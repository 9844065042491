import React from 'react';
import { Row, Col } from 'antd';
import Button from '@components/Button';
import InputWrapper, { InputName } from '@components/Input';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

interface iLoginEmailFormProps {
  handleSubmit: any;
  getValues: (field?: 'email') => void;
  setValue: UseFormSetValue<{ email: string }>;
  onSubmit: any;
  register: UseFormRegister<any>;
  errors: any;
  error: string;
  isValid: boolean;
}

const LoginEmailForm: React.FC<iLoginEmailFormProps> = ({
  handleSubmit,
  onSubmit,
  register,
  getValues,
  setValue,
  errors,
  error,
  isValid,
}) => {
  const { t: tc } = useTranslation('common');
  const router = useRouter();

  return (
    <>
      <p className="text-xl font-semibold mt-4">{tc('welcome_to_oyen')}</p>
      <div className="mt-4 flex flex-row">
        <p className="mr-1">{tc('new_here')}</p>
        <span
          onClick={() => {
            router.push('/eligibility/v4');
          }}
        >
          <a className="text-secondary hover:underline">
            {tc('get_your_pet_insured')}{' '}
          </a>
        </span>
      </div>
      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={[16, 12]}>
          <Col xl={24} xs={24}>
            <InputWrapper
              label={tc('email')}
              inputProps={{
                value: getValues('email'),
                input: InputName.Email,
                setValue,
                name: 'email',
                register,
              }}
              errors={errors}
            />
          </Col>
        </Row>
        {error && <p className="input-text mt-2 text-red-500">{error}</p>}
        <footer className="actions lg:mt-4 mt-6 px-2">
          <Row gutter={[16, 12]} className="w-full flex-grow">
            <Col xl={24} xs={24}>
              <Button submit className="w-full" disabled={!isValid}>
                {tc('submit')}
              </Button>
            </Col>
          </Row>
        </footer>
      </form>

      <div className="mt-4 flex flex-row">
        <div className="font-medium w-[50%] ">
          <span className="mr-1">{tc('need_help')}</span>

          <Link href="https://help.oyen.my/en">
            <a className="text-secondary hover:underline">
              {tc('visit_our_help_center')}
            </a>
          </Link>
        </div>
      </div>
    </>
  );
};

export default LoginEmailForm;
